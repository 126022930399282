import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactElement, ReactNode } from 'react';
import firebaseConfig from '@firebase/config';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebase = initializeApp(firebaseConfig);

export const firestore = getFirestore(firebase);

const queryClient = new QueryClient();

const FirebaseProvider = ({
  children,
}: FirebaseProviderProps): ReactElement => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

type FirebaseProviderProps = {
  children: ReactNode;
};

export default FirebaseProvider;
