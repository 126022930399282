import { ReactElement, useMemo } from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import colors from 'styles/colors';

const sizes = {
  medium: 16,
  large: 24,
};

const StarIcon = ({
  rating,
  index,
  size,
  error,
  selectable = false,
  handleClick,
  ...props
}: StarIconProps): ReactElement => {
  const Star = useMemo(() => {
    if (rating >= index) {
      return FaStar;
    }

    if (Math.round(rating) === index) {
      return FaStarHalfAlt;
    }

    return FaRegStar;
  }, [rating, index]);

  const starColor = useMemo(() => (error ? colors.red : colors.main), [error]);

  return (
    <button
      className="appearance-none pr-1 py-1 cursor-pointer disabled:cursor-default"
      type="button"
      onClick={() => handleClick(index)}
      disabled={!selectable}
    >
      <Star size={sizes[size]} color={starColor} {...props} />
    </button>
  );
};

type StarIconProps = {
  rating: number;
  index: number;
  size?: 'medium' | 'large';
  error?: boolean;
  selectable?: boolean;
  handleClick: (index: number) => void;
};

export default StarIcon;
