import {
  collection as firestoreCollection,
  query,
  where,
  DocumentData,
  CollectionReference,
  WhereFilterOp,
  doc,
} from 'firebase/firestore';
import {
  useFirestoreQuery,
  useFirestoreCollectionMutation,
  useFirestoreDocument,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';
import { firestore } from '@firebase/Provider';

export const useGetCollection = (
  collection: string,
  ref = query(firestoreCollection(firestore, collection)),
  options?: OptionsType,
): CollectionType => {
  const collectionData = useFirestoreQuery([collection], ref, options);
  const mutation = useFirestoreCollectionMutation(
    ref as CollectionReference<DocumentData>,
  );

  const add = (data: DocumentData) => mutation.mutate(data);

  if (!collectionData.data) {
    return { ...collectionData, data: [], add };
  }

  const data = collectionData.data.docs.map(col => ({
    id: col.id,
    ...col.data(),
  }));

  return { ...collectionData, data, add };
};

export const useGetCollectionWhere = (
  collection: string,
  [field, equality, value]: [string, WhereFilterOp, string | boolean],
  options?: OptionsType,
): CollectionType => {
  const ref = query(
    firestoreCollection(firestore, collection),
    where(field, equality, value),
  );

  return useGetCollection(collection, ref, options);
};

export const useGetDocument = (
  collection: string,
  id: string,
): DocumentType => {
  const ref = doc(firestore, collection, id);
  const product = useFirestoreDocument([collection, id], ref);
  const mutation = useFirestoreDocumentMutation(ref);

  return {
    ...product,
    data: product.data?.data(),
    set: (data: DocumentData) => mutation.mutate(data),
  };
};

type OptionsType = {
  subscribe: boolean;
};

type CollectionType = {
  isLoading: boolean;
  data: DocumentData[];
  add: (data: DocumentData) => void;
};

type DocumentType = {
  isLoading: boolean;
  data: DocumentData;
  set: (data: DocumentData) => void;
};
