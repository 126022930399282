import { ReactElement } from 'react';
import SoldOut from 'components/SoldOut/SoldOut';

const Price = ({ price, isSoldOut, size = 16 }: PriceProps): ReactElement => {
  if (isSoldOut) {
    return <SoldOut />;
  }

  const textSize = size === 16 ? 'text-xl' : 'text-2xl';

  return <div className={`my-1 font-bold ${textSize}`}>£{price}</div>;
};

type PriceProps = {
  price: string;
  isSoldOut?: boolean;
  size?: number;
};

export default Price;
