import { ReactElement, useCallback, useContext } from 'react';
import Link from 'next/link';
import Image from 'components/Image/Image';
import Price from 'components/Price/Price';
import BuyButton from 'components/BuyButton/BuyButton';
import MakeYourOwn from 'components/MakeYourOwn/MakeYourOwn';
import Rating from 'components/Rating/Rating';
import { CheckoutContext } from 'context/CheckoutContext';

const ProductCard = ({
  product,
  rating: { rating } = {},
}: ProductCardProps): ReactElement => {
  const { addToCart } = useContext(CheckoutContext);

  const handleBuyClick = useCallback(() => {
    addToCart(product.variants[0].id, 1);
  }, [product, addToCart]);

  const canBuy = product.availableForSale;

  return (
    <div className="group flex flex-col justify-between mb-6 cursor-pointer relative hover:bg-opacity-20 hover:bg-black">
      <Link href={`/product/${product.handle}`} passHref>
        <a href={`/product/${product.handle}`}>
          <div className="relative overflow-hidden transition-opacity duration-300 ease-in-out">
            <div className="relative group-hover:opacity-0">
              <Image
                src={product?.images[0].src}
                alt={product?.title}
                width={700}
                height={466}
              />
            </div>
            {product.images[1] && (
              <div className="opacity-0 group-hover:opacity-100 absolute top-0 left-0 w-full">
                <Image
                  src={product.images[1].src}
                  alt={`${product.title} contents`}
                  width={700}
                  height={466}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col items-center py-4 text-center flex-1">
            <MakeYourOwn />
            <h4 className="font-bold dark:text-white">{product?.title}</h4>

            <Price price={product.variants[0].price} />

            {rating && <Rating rating={rating} />}
          </div>
        </a>
      </Link>

      <BuyButton inStock={canBuy} handleClick={handleBuyClick} />
    </div>
  );
};

type ProductCardProps = {
  product: ProductType;
  rating: RatingType;
};

export default ProductCard;
