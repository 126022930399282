import { useState, useCallback, useEffect, ReactElement } from 'react';
import StarIcon from './StarIcon';

const Rating = ({
  rating,
  selectable,
  onChange,
  ...props
}: RatingProp): ReactElement => {
  const [value, setValue] = useState(rating);

  useEffect(() => setValue(rating), [rating]);

  const handleClick = useCallback(
    newValue => {
      if (!selectable) {
        return;
      }

      setValue(newValue);
      onChange(newValue);
    },
    [selectable, onChange, setValue],
  );

  if (!selectable && !rating) {
    return null;
  }

  return (
    <div className="flex">
      {[1, 2, 3, 4, 5].map(index => (
        <StarIcon
          key={index}
          rating={value}
          index={index}
          handleClick={newValue => handleClick(newValue)}
          selectable={selectable}
          {...props}
        />
      ))}
    </div>
  );
};

type RatingProp = {
  rating?: number;
  selectable?: boolean;
  onChange?: (value: number) => void;
  size?: 'medium' | 'large';
};

export default Rating;
