import { CheckoutContext } from 'context/CheckoutContext';
import React, { ReactElement, useContext } from 'react';
import { PrimaryButton } from 'styles/components/Button';

const BuyButton = ({ inStock, ...props }: BuyButtonProps): ReactElement => {
  const { addingProductToCart } = useContext(CheckoutContext);

  if (!inStock) {
    return <PrimaryButton disabled>Out of stock</PrimaryButton>;
  }

  return (
    <PrimaryButton {...props}>
      {addingProductToCart ? 'Adding...' : 'Add to cart'}
    </PrimaryButton>
  );
};

type BuyButtonProps = {
  inStock: boolean;
  handleClick: () => void;
};

export default BuyButton;
