const colors = {
  main: '#ef3e73',
  hover: '#e71353',
  white: '#fff',
  light: '#f4f4f4',
  text: '#1a1a2e',
  dark: '#16213e',
  blue: '#0f3460',
  red: '#A20021',
  black: '#000',
};

export default colors;
