import { DocumentData } from '@firebase/firestore';

const getRating = (
  reviews: DocumentData[],
  productId: string,
): DocumentData => {
  if (!reviews) {
    return {};
  }

  return reviews.find(review => review.id === productId);
};

export default getRating;
