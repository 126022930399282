const firebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyBLCeTghSwMjUjVfyinqhz_mZycEMtZCuY',
  authDomain: 'craftypartybox-20261.firebaseapp.com',
  projectId: 'craftypartybox-20261',
  storageBucket: 'craftypartybox-20261.appspot.com',
  messagingSenderId: '343768339940',
  appId: '1:343768339940:web:0ef7dead4a24a3cb7ae3f1',
  measurementId: 'G-LTD5G6DT9X',
};

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export default firebaseConfig;
